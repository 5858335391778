import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AgencyComponent } from './components/agency/agency.component';
import { AutoServicingComponent } from './components/auto-servicing/auto-servicing.component';
import { CityComponent } from './components/city/city.component';
import { ConstructionComponent } from './components/construction/construction.component';
import { Creative1Component } from './components/creative1/creative1.component';
import { Creative2Component } from './components/creative2/creative2.component';
import { Creative3Component } from './components/creative3/creative3.component';
import { Creative4Component } from './components/creative4/creative4.component';
import { DoctorComponent } from './components/doctor/doctor.component';
import { Event1Component } from './components/event1/event1.component';
import { Event2Component } from './components/event2/event2.component';
import { GymComponent } from './components/gym/gym.component';
import { IcoComponent } from './components/ico/ico.component';
import { ItComponent } from './components/it/it.component';
import { PlumbingComponent } from './components/plumbing/plumbing.component';
import { RealEstateComponent } from './components/real-estate/real-estate.component';
import { Restaurant1Component } from './components/restaurant1/restaurant1.component';
import { Restaurant2Component } from './components/restaurant2/restaurant2.component';
import { Restaurant3Component } from './components/restaurant3/restaurant3.component';
import { SeoComponent } from './components/seo/seo.component';
import { Travel1Component } from './components/travel1/travel1.component';
import { Travel2Component } from './components/travel2/travel2.component';

const routes: Routes = [
  {path: '', component: ItComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }