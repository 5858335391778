<div class="it">
    <div class="coming-soon-area">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="coming-soon-content">
                        <div class="logo">
                            <a routerLink="/home"><img src="assets/img/signage.png" alt="image"></a>
                        </div>
                        <h1>We are coming soon</h1>
                        <p>Go Baluyot & Adion Law Office is a full service law firm and is a general professional partnership duly registered with the Securities and Exchange Commission and organized under the laws of the Philippines.</p>
                        <div id="timer">
                            <div id="days">
                                {{days}} <span>Days</span>
                            </div>
                            <div id="hours">
                                {{hours}} <span>Hours</span>
                            </div>
                            <div id="minutes">
                                {{minutes}} <span>Minutes</span>
                            </div>
                            <div id="seconds">
                                {{seconds}} <span>Seconds</span>
                            </div>
                        </div>
                        <div class="btn-box">
                            <button class="btn btn-light get-more-info-btn" (click)="toggleClass()">Contact Us</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Sidebar Modal -->
<div class="sidebar-modal" [class.active]="classApplied">
    <div class="sidebar-modal-inner">
        <div class="about-area">
            <div class="title">
                <h2>Connect With Us</h2>
                <ul class="social">
                    <li><a href="https://www.facebook.com/gobaluyotadion" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                    <li><a href="https://www.linkedin.com/company/go-baluyot-adion-law-office/" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                    <li><a href="https://www.instagram.com/gobaluyotadion.lawoffice/" target="_blank"><i class="fab fa-instagram"></i></a></li>
                </ul>
            </div>
        </div>
        <div class="contact-area">
            <div class="title">
                <h2>Contact Us</h2>
            </div>
            <div class="contact-form">
                <form id="contactForm">
                    <div class="form-group mb-3">
                        <input type="text" name="name" id="name" class="form-control" required placeholder="Name">
                    </div>
                    <div class="form-group mb-3">
                        <input type="email" name="email" id="email" class="form-control" required placeholder="Email">
                    </div>
                    <div class="form-group mb-3">
                        <textarea name="message" class="form-control" id="message" cols="30" rows="5" placeholder="Your Message"></textarea>
                    </div>
                    <button type="submit">Send Message</button>
                </form>
            </div>
            <div class="contact-info">
                <div class="contact-info-content">
                    <h3>Contact us by Phone Number or Email Address</h3>
                    <h2>
                        <a href="tel:+63459821726">+63 45 982 1726</a>
                        <span>OR</span>
                        <a href="tel:+639088134289">+63 908 813 4289</a>
                        <span>OR</span>
                        <a href="mailto:gobaluyotadion&#64;gmail.com">gobaluyotadion&#64;gmail.com</a>
                    </h2>
                    <br>
                </div>
            </div>
        </div>
        <span class="close-btn sidebar-modal-close-btn" (click)="toggleClass()"><i class="fas fa-times"></i></span>
    </div>
</div>